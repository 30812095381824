import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import {
  DEFAULT_LAT,
  DEFAULT_LNG,
  DEFAULT_RADIUS,
  DEFAULT_ZOOM,
} from '../../static/defaultPosition';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

import './map.scss';
import { Box, Container, useTheme } from '@mui/material';
import { getNearByPackages } from '../../actions/packages';
import { travelByMarker } from '../../static/marker';

export default function Map() {
  const theme = useTheme();
  const [mapInstance, setMapInstance] = useState(null);
  const [mapsApi, setMapsApi] = useState(null);
  const [circle, setCircle] = useState(null);
  const [center, setCenter] = useState({
    lat: Number(DEFAULT_LAT),
    lng: Number(DEFAULT_LNG),
  });
  const [circleRadius, setCircleRadius] = useState(Number(DEFAULT_RADIUS) * 1000);
  const [packages, setPackages] = useState([]);
  const [markerClusterer, setMarkerClusterer] = useState(null);
  const [markers, setMarkers] = useState([]);

  // const [region] = useState({
  //   latitude: DEFAULT_LAT,
  //   longitude: DEFAULT_LNG,
  //   zoom: DEFAULT_ZOOM,
  //   ready: false,
  // });

  // Function to create and cluster new markers
  const updateMarkers = () => {
    // Clear previous markers from the clusterer and map
    if (markerClusterer) {
      markerClusterer.clearMarkers();
    }

    // Generate new marker positions around the new center
    const newMarkers = packages.map((pkg) => {
      const toAddress = pkg?.to ? pkg?.to?.address.split(',').splice(-2).join(',') : '';
      const imageSource = pkg.travel
        ? travelByMarker(pkg?.travelBy ?? 'car')
        : require('../../assets/images/marker_package.png');
      return new mapsApi.Marker({
        position: {
          lat: pkg.latitude,
          lng: pkg.longitude
        },
        map: mapInstance,
        icon: {
          url: imageSource,
          scaledSize: new mapsApi.Size(40, 40),
          labelOrigin: {
            x: 20,
            y: 50
          }
        },
        label: {
          text: toAddress,
          fontSize: '10px',
          fontWeight: 'bold',
          top: "15px",
          color: pkg?.travel ? theme.palette.secondary.main : theme.palette.primary.main
        },
      });
    });

    const newClusterer = new MarkerClusterer({ map: mapInstance, markers: newMarkers });
    setMarkerClusterer(newClusterer); // Update clusterer with new markers
    setMarkers(newMarkers); // Update marker state
  }

  const handleCenterChanged = async () => {
    if (mapInstance) {
      const newCenter = mapInstance.getCenter();
      setCenter({
        lat: newCenter.lat(),
        lng: newCenter.lng(),
      });
      await updatePackages();
      updateMarkers(newCenter);
    }
  };

  const updatePackages = async () => {
    setPackages(await getNearByPackages(center.lat, center.lng, undefined, undefined, DEFAULT_RADIUS));
  }

  // Initialize the circle when map and mapsApi are loaded
  useEffect(() => {
    if (mapInstance && mapsApi && !circle) {
      const newCircle = new mapsApi.Circle({
        map: mapInstance,
        center,
        radius: circleRadius,
        fillColor: theme.palette.primary.main,
        fillWidth: 1,
        strokeColor: theme.palette.primary.main,
        strokeWidth: 2,
        fillOpacity: 0.3
      });

      setCircle(newCircle);
      updateMarkers(center);
    }

    if (circle) {
      circle.setCenter(center);
    }
  }, [mapInstance, mapsApi, center, circleRadius]);

  useEffect(() => {
    updatePackages();
  }, []);

  return (
    <Box component="section" sx={{ display: "flex" }}>
      <Container className='map'>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDpch8iIAPF_Tq-4f29gq8SJhotX83JN9M" }}
          defaultCenter={{ lat: DEFAULT_LAT, lng: DEFAULT_LNG }}
          center={center}
          zoom={DEFAULT_ZOOM}
          yesIWantToUseGoogleMapApiInternals={true}
          onChange={handleCenterChanged}
          // mapRef={(ref) => changeRef(ref)}
          // onMapReady={() => changeRegion({ ...region, ready: true })}
          // onRegionChangeComplete={currentRegion => updateRegion(currentRegion)}
          // showsUserLocation
          // loadingEnabled
          // customMapStyle={[
          //   ...customMapStyle,
          //   ...(theme === 'dark' ? darkCustomStyle : []),
          // ]}
          // userInterfaceStyle={theme}

          onGoogleApiLoaded={({ map, maps }) => {
            setMapInstance(map);
            setMapsApi(maps);
          }}
        >
        </GoogleMapReact>
      </Container>
    </Box>
  );
}
